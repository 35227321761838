<template>
  <div class="supervisor-gant-control">
    <div v-if="currentTerminal" class="supervisor-title__qount-quota">
      Максимальное кол-во авто в буфере&nbsp;:&nbsp;
      {{ currentTerminal['buffer_zone_size'] }}
    </div>
    <transition name="el-fade-in-linear">
      <div v-show="isShowBTNs" class="m-r-12 d-f-a-start">
        <span class="m-r-5">Изменения:</span>

        <iq-button @onClick="handleConfirmQuotas"> Подтвердить </iq-button>

        <iq-button color="primary" @onClick="handleCancelQuotas">
          Отклонить 00:{{ getIntervalTime }}
        </iq-button>
      </div>
    </transition>

    <iq-button color="error" @onClick="handleRemoveQuotas">
      Удалить все правила
    </iq-button>
  </div>
</template>

<script>
import { GET_TERMINAL_CURRENT_ID, GET_TERMINAL_LIST } from '@/store/actions'
import { mapGetters } from 'vuex'
import IqButton from '@/views/ui/components/buttons/IqButton'

export default {
  name: 'GantControl',
  components: { IqButton },
  props: {
    clearedInterval: {
      type: Boolean,
      default: false,
    },
    keyUpdateTimerQuotas: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      timer: null,
      interval: 30,
      isShowBTNs: false,
    }
  },
  computed: {
    ...mapGetters({
      terminalList: GET_TERMINAL_LIST,
      currentTerminalID: GET_TERMINAL_CURRENT_ID,
    }),
    currentTerminal() {
      if (this.terminalList.length > 0 && this.currentTerminalID) {
        return this.terminalList.filter(i => i.id === this.currentTerminalID)[0]
      }

      return null
    },
    getIntervalTime() {
      return this.interval.toString().length > 1
        ? this.interval
        : `0${this.interval}`
    },
  },
  watch: {
    clearedInterval(val) {
      if (val) {
        clearInterval(this.timer)
        this.isShowBTNs = false
      }
    },
    keyUpdateTimerQuotas() {
      this.showTimer()
    },
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    handleRemoveQuotas() {
      this.$emit('removeQuotas')
    },
    showTimer() {
      if (this.timer) {
        clearInterval(this.timer)
        this.interval = 30
      }

      this.timer = setInterval(() => {
        --this.interval
        this.isShowBTNs = true

        if (this.interval <= 0) {
          clearInterval(this.timer)
          this.isShowBTNs = false
          this.$emit('cancelUpdateQuotas')

          setTimeout(() => (this.interval = 30), 100)
        }
      }, 1000)
    },
    handleConfirmQuotas() {
      clearInterval(this.timer)
      this.isShowBTNs = false
      this.$emit('confirmUpdateQuotas')
      setTimeout(() => (this.interval = 30), 100)
    },
    handleCancelQuotas() {
      clearInterval(this.timer)
      this.isShowBTNs = false
      this.$emit('cancelUpdateQuotas')
      setTimeout(() => (this.interval = 30), 100)
    },
  },
}
</script>

<style lang="sass">
.supervisor-gant-control
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 16px
</style>
