var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "supervisor-gant-control" },
    [
      _vm.currentTerminal
        ? _c("div", { staticClass: "supervisor-title__qount-quota" }, [
            _vm._v(
              " Максимальное кол-во авто в буфере :  " +
                _vm._s(_vm.currentTerminal["buffer_zone_size"]) +
                " "
            ),
          ])
        : _vm._e(),
      _c("transition", { attrs: { name: "el-fade-in-linear" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isShowBTNs,
                expression: "isShowBTNs",
              },
            ],
            staticClass: "m-r-12 d-f-a-start",
          },
          [
            _c("span", { staticClass: "m-r-5" }, [_vm._v("Изменения:")]),
            _c("iq-button", { on: { onClick: _vm.handleConfirmQuotas } }, [
              _vm._v(" Подтвердить "),
            ]),
            _c(
              "iq-button",
              {
                attrs: { color: "primary" },
                on: { onClick: _vm.handleCancelQuotas },
              },
              [_vm._v(" Отклонить 00:" + _vm._s(_vm.getIntervalTime) + " ")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "iq-button",
        { attrs: { color: "error" }, on: { onClick: _vm.handleRemoveQuotas } },
        [_vm._v(" Удалить все правила ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }